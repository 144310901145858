import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image'
import {
  getCollaborationUrl,
  getProductUrl,
  getMentorshipUrl,
  isBottom,
  buildSliderSrcSetUrl,
  isBrowser,
} from '../lib/helpers'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import ArrowNext from '../images/icons/arrow-next.svg'
import InfoI from '../images/icons/info-i.svg'
import PortableText from '../components/portableText'
import classNames from 'classnames'
import SliderImg from '../components/slider-img'
import { isMobile } from 'react-device-detect'

class HeroSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      innerW: null,
      isMouseOverImage: false,
      isMouseOverLink: false,
      isMouseTooltipVisible: null,
      tooltipArrowDirection: null,
      isSlideTextVisible: false,
      isHeroScrolled: false,
    }
    this.back = this.back.bind(this)
    this.next = this.next.bind(this)
    this.containerRef = React.createRef()
    this.slideRef = React.createRef()
    this.toggleSlideText = this.toggleSlideText.bind(this)
    this.onMouseLeaveLink = this.onMouseLeaveLink.bind(this)
    this.onMouseEnterLink = this.onMouseEnterLink.bind(this)
    this.onMouseLeaveSlide = this.onMouseLeaveSlide.bind(this)
    this.onMouseEnterSlide = this.onMouseEnterSlide.bind(this)
    this.onMouseOverSlide = this.onMouseOverSlide.bind(this)
  }

  updateWidth = () => {
    if (window.innerWidth) {
      this.setState({
        innerW: window.innerWidth,
      })
    }
  }
  onScroll = () => {
    const el = this.containerRef.current
    const navHeight = 72
    if (window.pageYOffset <= navHeight) {
      this.setState({ isHeroScrolled: false })
    } else if (isBottom(el)) {
      this.setState({ isHeroScrolled: true })
    }
  }

  back() {
    this.slideRef.current.goBack()
  }

  next() {
    this.slideRef.current.goNext()
  }

  onSliderClick = () => {
    const tooltipArrowDirection = this.state.tooltipArrowDirection
    if (tooltipArrowDirection === 'right') {
      this.slideRef.current.goNext()
    } else if (tooltipArrowDirection === 'left') {
      this.slideRef.current.goBack()
    } else {
      this.slideRef.current.goNext()
    }
  }

  getSlideTextLink = slide => {
    if (slide.pageRoute) {
      return slide.pageRoute.slug
    } else if (slide.collaborationRoute) {
      return getCollaborationUrl(slide.collaborationRoute.slug)
    } else if (slide.conversationRoute) {
      // tbd overlay link
      return
    } else if (slide.productRoute) {
      return getProductUrl(slide.productRoute.slug)
    } else if (slide.mentorshipRoute) {
      return getMentorshipUrl(slide.mentorshipRoute)
      // external
    } else if (slide.link) {
      return slide.link
    }
  }

  toggleSlideText = () => {
    this.setState({
      isSlideTextVisible: !this.state.isSlideTextVisible,
    })
  }

  updateTooltipArrow(e) {
    let halfWidth = window.innerWidth / 2
    let mouseX = e.clientX
    let pos
    switch (true) {
      case mouseX <= halfWidth:
        pos = 'left'
        break
      case mouseX > halfWidth:
        pos = 'right'
        break
      default:
        pos = undefined
    }
    this.setState({ tooltipArrowDirection: pos })
  }

  onMouseEnterSlide(e) {
    this.updateTooltipArrow(e)
    this.setState({ isMouseTooltipVisible: true })
  }

  onMouseOverSlide(e) {
    this.updateTooltipArrow(e)
  }

  onMouseLeaveSlide() {
    this.setState({ isMouseTooltipVisible: false })
  }

  onMouseEnterLink() {
    this.setState({ isMouseOverLink: true, isMouseTooltipVisible: false })
  }

  onMouseLeaveLink() {
    this.setState({ isMouseOverLink: false, isMouseTooltipVisible: true })
  }

  render() {
    const { hidden, index } = this.props
    const slides = this.props.heroSlides
    const { isMouseOverLink, isSlideTextVisible, innerW } = this.state
    const sliderSettings = {
      duration: 3000,
      transitionDuration: 500,
      infinite: true,
      arrows: false,
      easing: 'ease',
      autoplay: true,
    }
    const sliderClasses = classNames({
      'is-hero-scrolled': this.state.isHeroScrolled,
      'is-mouse-tooltip-hidden': !this.state.isMouseTooltipVisible,
    })
    const slideInfoClasses = classNames({
      'is-slide-text-visible': this.state.isSlideTextVisible,
    })
    const slideToggleButtonClasses = classNames({
      'btn--active': this.state.isSlideTextVisible,
      'btn--bg-transparent': this.state.isSlideTextVisible,
    })
    return (
      <>
        {!hidden && (
          <div
            className={`hero-slider slide-container ${sliderClasses} is-pb-index-${index}`}
            id="hero-slider"
            ref={this.containerRef}
          >
            <Fade ref={this.slideRef} {...sliderSettings}>
              {slides &&
                slides.map((slide, i) => {
                  const image = slide.image
                  const _innerWidth = innerW ? Math.round(innerW) : 2400
                  const type = 'hero'
                  const initialSrc =
                    image && buildSliderSrcSetUrl(image, _innerWidth, type, isMobile)
                  const tabletSrc = image && buildSliderSrcSetUrl(image, 1024, type, isMobile)
                  const desktopSrc = image && buildSliderSrcSetUrl(image, 1400, type, isMobile)
                  const widescreenSrc = image && buildSliderSrcSetUrl(image, 1920, type, isMobile)
                  const XLSrc = image && buildSliderSrcSetUrl(image, 2400, type, isMobile)
                  const slideText = slide.slideText
                  this.getSlideTextLink(slide)
                  const slideIndexText = `Preview slide ${i + 1}/${slides.length}`
                  const genAltText = slideText && toPlainText(slideText)
                  return (
                    <div className="each-fade" key={slide._key}>
                      <div
                        className="hero-slider__slide"
                        onClick={!isMouseOverLink && this.onSliderClick}
                        onMouseEnter={this.onMouseEnterSlide}
                        onMouseMove={this.onMouseOverSlide}
                        onMouseLeave={this.onMouseLeaveSlide}
                      >
                        {image && image.asset && (
                          <SliderImg
                            src={initialSrc}
                            srcSet={`${tabletSrc} 1024w, ${desktopSrc} 1400w, ${widescreenSrc} 1920w, ${XLSrc} 2400w`}
                            alt={
                              image.alt
                                ? `${slideIndexText}: ${image.alt}`
                                : genAltText
                                ? `${slideIndexText}: ${genAltText}`
                                : `${slideIndexText}`
                            }
                            loadImg
                            index={i}
                          />
                        )}
                        {slideText && (
                          <div
                            className={`hero-slide__info ${slideInfoClasses}`}
                            onClick={this.toggleSlideText}
                          >
                            <div
                              className={`hero-slide__info-text`}
                              onMouseEnter={this.onMouseEnterLink}
                              onMouseLeave={this.onMouseLeaveLink}
                            >
                              <PortableText blocks={slideText} />
                            </div>
                            <div
                              className="hero-slide__info-toggle"
                              onMouseEnter={this.onMouseEnterLink}
                              onMouseLeave={this.onMouseLeaveLink}
                            >
                              <button
                                className={`btn btn--bg-transparent icon-btn ${slideToggleButtonClasses}`}
                              >
                                <InfoI />
                              </button>
                            </div>
                          </div>
                        )}
                        <MouseTooltip
                          offsetX={-17}
                          offsetY={-17}
                          visible={this.state.isMouseTooltipVisible}
                        >
                          <div className={`mouse-tooltip is-${this.state.tooltipArrowDirection}`}>
                            <ArrowNext />
                          </div>
                        </MouseTooltip>
                      </div>
                    </div>
                  )
                })}
            </Fade>
          </div>
        )}
      </>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    document.addEventListener('scroll', this.onScroll)
    this.updateWidth()
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }
}

export default HeroSlider
