import React from 'react'
import { Link } from 'gatsby'
import InfoI from '../images/icons/info-i.svg'

const TeamMemberGroup = props => {
  const { teamMembers } = props
  return (
    <div>
      <div className="btn btn--info btn--header team-member-group__header">
        Advisory Board Members
      </div>
      <ul className="team-member-group__list">
        {teamMembers.map(node => {
          const { title, slug } = node
          return (
            <li key={node._id}>
              {slug && (
                <Link to={`/team/${slug.current}`}>
                  <div className="btn icon-btn">
                    <InfoI />
                  </div>
                  <div className="team-member-group__name">{title}</div>
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TeamMemberGroup
