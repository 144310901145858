import React, { useState } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import ContentContainer from '../containers/content'
import PortableText from '../components/portableText'
import HeroSlider from '../components/hero-slider'
import CollaborationPreview from '../components/collaboration-preview'
import ProductPreview from '../components/product-preview'
import MentorshipPreview from '../components/mentorship-preview'
import ConversationPreview from '../components/conversation-preview'
import CustomColumns from '../components/custom-columns'
import PressLinksGroup from '../components/press-links-group'
import TeamMemberGroup from '../components/team-member-group'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
        mobFooterHidden
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
  }
`

const Page = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const ogImage = site.openGraph.image

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || data.route.page

  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'heroSlider':
          el = <HeroSlider key={c._key} {...c} index={i} />
          break
        case 'customColumns':
          el = <CustomColumns key={c._key} {...c} index={i} />
          break
        case 'customTextSection':
          el = <PortableText key={c._key} blocks={c.text} index={i} />
          break
        case 'productReference':
          el = (
            <ProductPreview
              key={c._key}
              {...c.product}
              customPreviewImageSize={c.customPreviewImageSize}
              rawImageReference={true}
              index={i}
            />
          )
          break
        case 'collaborationReference':
          el = (
            <CollaborationPreview
              key={c._key}
              {...c.collaboration}
              customPreviewImageSize={c.customPreviewImageSize}
              rawImageReference={true}
              index={i}
            />
          )
          break
        case 'mentorshipReference':
          el = (
            <MentorshipPreview
              key={c._key}
              {...c.mentorship}
              customPreviewImageSize={c.customPreviewImageSize}
              rawImageReference={true}
              index={i}
            />
          )
          break
        case 'conversationReference':
          el = (
            <ConversationPreview
              key={c._key}
              {...c.conversation}
              customVideoPreviewImageSize={c.customVideoPreviewImageSize}
              rawImageReference={true}
              index={i}
            />
          )
          break
        case 'pressLinksGroup':
          el = <PressLinksGroup key={c._key} {...c} index={i} />
          break
        case 'teamMemberGroup':
          el = <TeamMemberGroup key={c._key} {...c} index={i} />
          break
        default:
          el = null
      }
      return el
    })

  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const SEOtitle = pageTitle ? `Balkan...${pageTitle}` : `Balkan...Projects`

  return (
    <ContentContainer>
      <SEO
        title={SEOtitle || site.title}
        description={site.description}
        keywords={site.keywords}
        image={ogImage}
      />
      <div className="page-container" id="page-container">
        {content}
      </div>
    </ContentContainer>
  )
}

export default Page
