import React from 'react'
import cx from 'classnames'

class SliderImg extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      viewed: false,
    }
  }

  onLoad = e => {
    const { index, setFirstImageWidth } = this.props
    this.setState({
      loaded: true,
    })
    if (index === 0) {
      if (setFirstImageWidth) {
        setFirstImageWidth(e.currentTarget.clientWidth)
      }
    }
  }

  render() {
    const {
      src,
      srcSet,
      hasBgImage,
      bgImageSrc,
      classes,
      dataRef,
      alt,
      onMouseEnter,
      onMouseMove,
      onMouseLeave,
      loadImg,
      index,
    } = this.props
    const { loaded, viewed } = this.state
    const load = loadImg || viewed

    return (
      <>
        {/* {hasBgImage && (
          <img
            src={bgImageSrc}
            className={`slider-img-bg ${classes} ${loaded ? 'main-img-loaded' : ''}`}
            alt=""
            style={{
              position: `absolute`,
              width: `auto`,
              // height: `${framed ? 'auto' : '100%'}`,
            }}
          />
        )} */}
        <img
          src={load ? src : ''}
          srcSet={load ? srcSet : ''}
          alt={alt ? alt : ''}
          className={cx('slider-img', `${classes ? classes : ''}`, {
            loaded: loaded,
            viewed: viewed,
          })}
          data-ref={dataRef}
          onMouseEnter={onMouseEnter}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onLoad={e => {
            this.onLoad(e)
          }}
          index={index}
        />
      </>
    )
  }
  componentDidUpdate() {
    const { index, slideIndex } = this.props
    if (index === slideIndex) {
      if (!this.state.viewed) {
        this.setState({
          viewed: true,
        })
      }
    }
  }
}

export default SliderImg
