import React from 'react'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'
import Download from '../images/icons/download.svg'
import ExternalLink from '../images/icons/external-link.svg'

class PressLinksGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: this.props.isOpen ? 'auto' : 0,
    }
  }

  toggleOneOpenClass = () => {
    const els = Array.from(document.querySelectorAll('.press-links-group'))
    const open = els.filter(el => el.classList.contains('is-in'))
    const pageContainer = document.getElementById('page-container')
    if (open.length === 1) {
      pageContainer.classList.add('is-one-press-links-group-open')
    } else {
      pageContainer.classList.remove('is-one-press-links-group-open')
    }
  }

  toggleHeight = () => {
    const { subsections, index } = this.props
    const { height } = this.state
    if (subsections) {
      this.setState({
        height: height === 0 ? 'auto' : 0,
      })
    }
  }

  render() {
    const { title, subsections } = this.props
    const { height } = this.state
    const classes = classNames({
      'is-in': this.state.height !== 0,
      'no-subsections': !subsections,
    })
    return (
      <div className={`press-links-group ${classes}`}>
        <div>
          <div
            aria-expanded={height !== 0}
            aria-controls={`Open: ${title}`}
            onClick={this.toggleHeight}
            className="press-links-group__title-container"
          >
            <div className="press-links-group__title">
              <time dateTime="2020">{title}</time>
            </div>
          </div>
        </div>
        <AnimateHeight duration={500} height={height}>
          {subsections &&
            subsections.map(subsection => {
              const pressArticles = subsection.pressArticles
              return (
                <div className="press-links-group__subsection" key={subsection._key}>
                  <div className="btn btn--info press-links-group__subsection-title">
                    {subsection.title}
                  </div>
                  {pressArticles &&
                    pressArticles.map(link => (
                      <a
                        className="press-link"
                        href={link._rawDownload ? link._rawDownload.asset.url : link.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={link._key}
                      >
                        <div className="btn icon-btn press-article-icon press-article-icon--mobile">
                          {link.download ? <Download /> : <ExternalLink />}
                        </div>
                        <div className="press-link__title">
                          {link.publisher ? (
                            <>
                              <span className="press-link__article-name">
                                <span>
                                  <div className="btn icon-btn press-article-icon press-article-icon--desktop">
                                    {link.download ? <Download /> : <ExternalLink />}
                                  </div>
                                  <span>{link.title}</span>
                                  {/* <span className="comma">,</span> */}
                                </span>
                              </span>
                              <em className="press-link__publisher">{link.publisher}</em>
                            </>
                          ) : (
                            <span className="press-link__article-name">
                              <span>
                                <div className="btn icon-btn press-article-icon press-article-icon--desktop">
                                  {link.download ? <Download /> : <ExternalLink />}
                                </div>
                                <span>{link.title}</span>
                              </span>
                            </span>
                          )}
                        </div>
                      </a>
                    ))}
                </div>
              )
            })}
        </AnimateHeight>
      </div>
    )
  }
  componentDidMount() {
    this.toggleOneOpenClass()
  }
  componentDidUpdate() {
    this.toggleOneOpenClass()
  }
}

export default PressLinksGroup
